import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode, Ref } from 'react';
import { ParentBackground } from '@/ui/components/types/shared.ts';

export enum ButtonKind {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonStyleGuide {
  BRAND = 'brand',
  BRAND_INVERTED = 'brand_inverted',
  DANGER = 'danger',
  DANGER_INVERTED = 'danger-inverted',
  WAITING = 'waiting',
  WAITING_INVERTED = 'waiting-inverted',
  SUCCESS = 'success',
  SUCCESS_INVERTED = 'success_inverted',
  GRADIENT = 'gradient',
}

enum AdditionalButtonStyleGuide {
  DISABLED = 'disabled',
}

export const MergedButtonStyleGuide = {
  ...ButtonStyleGuide,
  ...AdditionalButtonStyleGuide,
};

export interface IButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  styleGuide?: ButtonStyleGuide;
  kind?: ButtonKind;
  icon?: ReactNode;
  loading?: boolean;
  fullWidth?: boolean;
  parentBackground?: ParentBackground;
  transparent?: boolean;
  ref?: Ref<HTMLButtonElement> | undefined;
}
