import { Rfi } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetRfisProps extends IBaseRequest {
  clientId: string;
}

export async function getRfi({ api, clientId }: IGetRfisProps): Promise<Rfi[]> {
  const response = await api.get<Rfi[]>(`clients/${clientId}/rfi`);

  return response.data;
}
