import { GeneratedUserDocument } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IGetTransactionPdfProps extends IBaseRequest {
  clientId: string;
  id: string;
  params: {
    pdfDisplayName: string;
    pdfFilename: string;
  };
}

// TODO: Update backend contract
type IGetTransactionPdfResponse = GeneratedUserDocument | IBaseResponse<null>;

function isGeneratedUserDocument(
  response: IGetTransactionPdfResponse,
): response is GeneratedUserDocument {
  return Boolean((response as GeneratedUserDocument).id);
}

export async function getTransactionPdf({
  api,
  clientId,
  id,
  params,
}: IGetTransactionPdfProps) {
  try {
    const response = await api.get<IGetTransactionPdfResponse>(
      `clients/${clientId}/display_transactions/${id}/pdf`,
      {
        params,
      },
    );

    if (isGeneratedUserDocument(response.data)) {
      return createSuccessResponse({
        result: response.data,
      });
    }

    return createErrorResponse({
      errors: response.data.errors,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
