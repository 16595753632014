export function getConfiguration() {
  return {
    app: {
      version: import.meta.env.npm_package_version,
    },
    node: {
      env: import.meta.env.NODE_ENV,
    },
    security: {
      metricass: {
        version: import.meta.env.VITE_ASSPRINT_VERSION,
        salt: import.meta.env.VITE_ASSPRINT_SALT,
      },
      fingerprint: {
        apiKey: import.meta.env.VITE_FINGERPRINT_API_KEY,
        criticalScore: import.meta.env.VITE_FINGERPRINT_SCORE_CRITICAL,
      },
      session: {
        tokenLifeTimeInS: Number(import.meta.env.VITE_JWT_TOKEN_LIFETIME),
        sessionDeltaUpdateTimeInS:
          Number(import.meta.env.VITE_JWT_TOKEN_LIFETIME) * 0.25,
        sessionAutoUpdateTimeInS: 15,
        idleStartInS: Number(
          import.meta.env.VITE_SESSION_IDLE_MIN_DURATION_IN_SECONDS,
        ),
        storageKeys: {
          accessToken: 'ariv.at',
          refreshToken: 'ariv.rt',
          serverTime: 'ariv.std',
          lastClient: 'ariv.lc',
        },
      },
    },
    api: {
      bankApi: import.meta.env.VITE_BANK_API_HOST,
      wsApi: import.meta.env.VITE_CENTRIFUGE_URL,
    },
    sentry: {
      dsn: import.meta.env.VITE_SENTRY_DSN,
      currentEnv: import.meta.env.VITE_SENTRY_CURRENT_ENV,
    },
    posthog: {
      apiKey: import.meta.env.VITE_POSTHOG_PROJECT_API_KEY,
      projectUrl: import.meta.env.VITE_POSTHOG_PROJECT_URL,
    },
    mobile: {
      onboardingParam: import.meta.env.VITE_ONBOARDING_PARAM,
    },
    special: {
      twilioFail: import.meta.env.VITE_TWILIO_FAIL === 'true',
      underMaintenance: import.meta.env.VITE_UNDER_MAINTENANCE === 'true',
      festival: import.meta.env.VITE_FESTIVAL === 'true',
      loginUniversalLink: import.meta.env.VITE_LOGIN_UNIVERSAL_LINK ?? '',
      withoutOnboarding: import.meta.env.VITE_WITHOUT_ONBOARDING === 'true',
    },
    captcha: {
      googleRecaptchaKey: import.meta.env.VITE_GOOGLE_RE_CAPTCHA_KEY,
      turnstileKey: import.meta.env.VITE_TURNSTILE_KEY,
    },
    support: {
      feedbackEmail: import.meta.env.VITE_FEEDBACK_EMAIL,
    },
    externalLinks: {
      landingUrl: import.meta.env.VITE_BANK_LANDING_URL,
      mobAppIos: import.meta.env.VITE_IOS_APP_STORE_LINK,
      mobAppAndroid: import.meta.env.VITE_ANDROID_APP_STORE_LINK,
    },
    wise: {
      termsLink: import.meta.env.VITE_WISE_TERMS,
      privacyPolicyLink: import.meta.env.VITE_WISE_PRIVACY_POLICY,
    },
  };
}
