import { ClassAttributes, ElementType, HTMLAttributes, Ref } from 'react';

export enum TextKind {
  AMOUNT_BOLD = 'amount-bold',
  AMOUNT_DEFAULT = 'amount-default',
  TITLE_1_BOLD = 'title-1-bold',
  TITLE_1_DEFAULT = 'title-1-default',
  TITLE_2_BOLD = 'title-2-bold',
  TITLE_2_MEDIUM = 'title-2-medium',
  TITLE_2_DEFAULT = 'title-2-default',
  TITLE_3_BOLD = 'title-3-bold',
  TITLE_3_MEDIUM = 'title-3-medium',
  TITLE_3_DEFAULT = 'title-3-default',
  BODY_BOLD = 'body-bold',
  BODY_MEDIUM = 'body-medium',
  BODY_DEFAULT = 'body-default',
  FOOTNOTE_BOLD = 'footnote-bold',
  FOOTNOTE_MEDIUM = 'footnote-medium',
  FOOTNOTE_DEFAULT = 'footnote-default',
  CAPTION_BOLD = 'caption-bold',
  CAPTION_MEDIUM = 'caption-medium',
  CAPTION_DEFAULT = 'caption-default',
  CAPTION_2_BOLD = 'caption-2-bold',
  CAPTION_2_DEFAULT = 'caption-2-default',
  // CAPTION_1_BOLD = 'caption-1-bold',
  // CAPTION_1_DEFAULT = 'caption-1-default',
  // CAPTION_2_BOLD = 'caption-2-bold',
  // CAPTION_2_DEFAULT = 'caption-2-default',
  // CAPTION_3_SEMIBOLD = 'caption-3-semibold',
}

export interface ITextProps
  extends ClassAttributes<HTMLParagraphElement>,
    HTMLAttributes<HTMLParagraphElement> {
  as?: ElementType;
  kind?: TextKind | undefined;
  ellipsis?: boolean;
  lineThrough?: boolean;
  ref?: Ref<HTMLParagraphElement> | undefined;
}
