import { IContact } from '@/entities';
import { IBaseRequest } from '@/api/types';

export interface IGetContactsParams {
  kind?: string;
  favorite?: boolean;
  paymentKind?: string;
  detailsCode?: string;
  detailsValue?: string;
  empty?: boolean;
  search?: string;
  limit?: number;
  offset?: number;
  orderBy?: string;
  orderDirection?: string;
}

interface IGetContactsProps extends IBaseRequest {
  clientId?: string;
  params?: IGetContactsParams;
}

export async function getContacts({
  api,
  clientId,
  params,
}: IGetContactsProps): Promise<IContact[]> {
  const response = await api.get<IContact[]>(`clients/${clientId}/contacts`, {
    params,
  });

  return response.data;
}
