import { PropsWithChildren, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { MainAppContainer } from './index.styles';

export function AppContainer({ children }: PropsWithChildren<unknown>) {
  return (
    <>
      <ToastContainer />
      <MainAppContainer>
        <Suspense fallback={<div>Checking authorization...</div>}>
          {children}
        </Suspense>
      </MainAppContainer>
    </>
  );
}
