import { Suspense } from 'react';
import { PosthogProvider } from '@/posthog/PosthogProvider';
import { ThemeProvider } from '@/ui/ThemeProvider';
import { FontProvider } from '@/ui/FontProvider';
import { FingerprintProvider } from '@/security/fingerprint';
import { ErrorController } from '@/sentry/ErrorController';
import { AxiosClientProvider } from '@/api';
import { LocationObserver } from './LocationProvider';
import { AppContainer } from './AppContainer';
import { MainRouter } from './MainRouter';
import { BrowserRouter } from 'react-router-dom';
import './LibPatchers';
import { AuthorizationProvider } from '@/auth/AuthorizationProvider.tsx';

export function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <FontProvider>
          <AxiosClientProvider>
            <ErrorController>
              <FingerprintProvider>
                <PosthogProvider>
                  <AppContainer>
                    <AuthorizationProvider>
                      <LocationObserver>
                        <Suspense
                          fallback={<div>Launching the application...</div>}
                        >
                          <MainRouter />
                        </Suspense>
                      </LocationObserver>
                    </AuthorizationProvider>
                  </AppContainer>
                </PosthogProvider>
              </FingerprintProvider>
            </ErrorController>
          </AxiosClientProvider>
        </FontProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
