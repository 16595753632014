import { ISuggestedPaymentDetails, PaymentMethodName } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetPaymentDetailsPredictionsProps extends IBaseRequest {
  accountNo: string;
  countryId: string;
  currencyId: string;
  routingNo: string;
  paymentKind: PaymentMethodName;
}

export async function getPaymentDetailsPredictions({
  api,
  accountNo,
  countryId,
  currencyId,
  routingNo,
  paymentKind,
}: IGetPaymentDetailsPredictionsProps): Promise<ISuggestedPaymentDetails> {
  const response = await api.post<ISuggestedPaymentDetails>(
    `payment_details_lookup/${paymentKind}`,
    {
      accountNo,
      countryId,
      currencyId,
      routingNo,
    },
  );

  return response.data;
}
