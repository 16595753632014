import { IBaseRequest } from '@/api/types';
import { SupportingDocumentsRequest } from '@/entities';

interface IGetSupportingDocumentsRequestProps extends IBaseRequest {
  clientId: string;
  supportingDocumentsRequestId: string;
}

export async function getSupportingDocumentsRequest({
  api,
  clientId,
  supportingDocumentsRequestId,
}: IGetSupportingDocumentsRequestProps): Promise<SupportingDocumentsRequest> {
  const response = await api.get<SupportingDocumentsRequest>(
    `clients/${clientId}/supporting_documents_requests/${supportingDocumentsRequestId}`,
  );

  return response.data;
}
