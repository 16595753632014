import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosInstance } from 'axios';
import { useTokens } from './useTokens';
import { getServerTime, useServerTime } from './useServerTime';
import { useSessionTracking } from './useSessionTracking';
import { useAutoRefreshToggle } from './useAutoRefreshToggle';
import { useApi } from '@/api';
import { useDevice } from '@/device/useDevice.ts';
import { config } from '@/config';
import { AuthorizedState } from '@/auth/types.ts';
import { getAccessToken } from '@/auth/functions/getAccessToken.ts';
import { getRefreshToken } from '@/auth/functions/getRefreshToken.ts';
import { checkToken } from '@/auth/functions/checkToken.ts';
import { useModalController } from '@/ui/hooks/useModalController.ts';

export function useAuthorization() {
  const { api, client, queryClient } = useApi();
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState<AuthorizedState>(
    AuthorizedState.FETCHING,
  );

  const { autoRefreshEnabled, enableAutoRefresh, disableAutoRefresh } =
    useAutoRefreshToggle();
  const {
    accessToken,
    refreshToken,
    handleSetTokens,
    handleClearTokens,
    handleSetAuthHeader,
  } = useTokens();
  const { isWebview } = useDevice();
  useServerTime();

  const checkAuthorization = useCallback(
    async (accessToken: string, refreshToken: string) => {
      const time = getServerTime();
      const isAccessTokenCorrect = checkToken(accessToken, 0, time);
      const isRefreshTokenCorrect = checkToken(refreshToken, 0, time);

      if (isAccessTokenCorrect && isRefreshTokenCorrect) {
        handleSetTokens({
          accessToken,
          refreshToken,
        });
        const result = await api.auth.getTest({ api: client });

        if (result) {
          setAuthorized(AuthorizedState.AUTHORIZED);
          return;
        }
      }

      if (!isAccessTokenCorrect && isRefreshTokenCorrect) {
        const refreshResponse = await api.auth.refreshSession({
          api: client,
          refreshToken,
        });

        if (refreshResponse.success) {
          handleSetTokens({
            accessToken: refreshResponse.result.at,
            refreshToken: refreshResponse.result.rt,
          });
          setAuthorized(AuthorizedState.AUTHORIZED);
          return;
        }
      }

      setAuthorized(AuthorizedState.UNAUTHORIZED);
      handleClearTokens();
    },
    [handleClearTokens, handleSetTokens, api, client],
  );

  const forceCheckAuthorization = useCallback(async () => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    await checkAuthorization(accessToken, refreshToken);
  }, [checkAuthorization]);

  useEffect(() => {
    forceCheckAuthorization();
  }, [accessToken, refreshToken]);

  const navigateToRoot = useCallback(() => {
    // Add mob app support
    // if (isWebview) {
    //   window.location.href = `${window.location.origin}${IsomorphicRoutes.mobappTerminateView}`;
    // }

    if (!isWebview) {
      navigate('/');
    }
  }, [isWebview, navigate]);

  const handle401 = useCallback(
    async (error: AxiosError, axios: AxiosInstance) => {
      const time = getServerTime();
      const accessToken = getAccessToken();
      const isAccessTokenCorrect = checkToken(accessToken, 0, time);

      if (isAccessTokenCorrect) {
        return axios({
          ...error.config,
          headers: {
            ...error?.config?.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }

      handleClearTokens();
      navigateToRoot();
      return Promise.reject(error);
    },
    [handleClearTokens, navigateToRoot],
  );

  useEffect(() => {
    const interceptors = [
      client.interceptors.response.use(undefined, (error: AxiosError) => {
        if (error?.response?.status === 419) {
          window.location.reload();
        }
        if (error?.response?.status === 401) {
          return handle401(error, client);
        }

        return Promise.reject(error);
      }),
    ];

    return () => {
      if (interceptors.length) {
        const [bankAxiosInterceptor] = interceptors;
        client.interceptors.response.eject(bankAxiosInterceptor);
      }
    };
  }, [api, client, handle401]);

  const logoutPopup = useModalController();

  const processLogout = useCallback(async () => {
    const success = await api.auth.deleteSession({ api: client });
    if (success) {
      logoutPopup.close();
      handleClearTokens();
      navigateToRoot();
    }
  }, [api.auth, client, handleClearTokens, logoutPopup, navigateToRoot]);

  const {
    sessionIdle,
    sessionExpired,
    sessionTimeout,
    remainingTime,
    timeoutSessionPopup,
    clearTracking,
  } = useSessionTracking({
    enabled: true,
    authorized,
    clearTokens: handleClearTokens,
    disableAutoRefresh,
  });

  const handleRefresh = useCallback(async () => {
    const refreshToken = getRefreshToken();
    const newTokens = await api.auth.refreshSession({
      api: client,
      refreshToken,
    });

    if (newTokens.success) {
      handleSetTokens({
        accessToken: newTokens.result.at,
        refreshToken: newTokens.result.rt,
      });
    }
  }, [api.auth, client, handleSetTokens]);

  const makeRefreshIfNeeded = useCallback(() => {
    const time = getServerTime();
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    const needRefresh = checkToken(
      accessToken,
      config.security.session.sessionDeltaUpdateTimeInS,
      time,
    );
    const isRefreshTokenCorrect = checkToken(refreshToken, 0, time);

    if (needRefresh && isRefreshTokenCorrect) {
      handleRefresh();
    }
  }, [handleRefresh]);

  useEffect(() => {
    if (autoRefreshEnabled) {
      const refreshInterval = setInterval(
        () => makeRefreshIfNeeded(),
        config.security.session.sessionAutoUpdateTimeInS * 1000,
      );

      return () => clearInterval(refreshInterval);
    }
  }, [autoRefreshEnabled]);

  useEffect(() => {
    if (sessionExpired && isWebview) {
      navigateToRoot();
    }
  }, [sessionExpired]);

  useEffect(() => {
    if (authorized === AuthorizedState.UNAUTHORIZED) {
      queryClient.removeQueries();
    }
  }, [authorized, queryClient]);

  return {
    authorized,
    session: {
      sessionIdle,
      sessionExpired,
      sessionTimeout,
      remainingTime,
      timeoutSessionPopup,
      clearTracking,
      enableAutoRefresh,
      disableAutoRefresh,
      makeRefreshIfNeeded,
    },
    authTokenLogic: {
      accessToken,
      refreshToken,
      handleClearTokens,
      handleSetTokens,
      handleSetAuthHeader,
      handleRefresh,
    },
    forceCheckAuthorization,
    logout: {
      popup: logoutPopup,
      process: processLogout,
    },
  };
}
