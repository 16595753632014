import { FlexibleObject } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IFetchDocumentsCountProps extends IBaseRequest {
  params?: FlexibleObject;
}

interface IFetchDocumentsCountResponse {
  count?: number;
}

export async function fetchDocumentsCount({
  api,
  params,
}: IFetchDocumentsCountProps): Promise<IFetchDocumentsCountResponse> {
  const response = await api.get<IFetchDocumentsCountResponse>(
    `documents/count`,
    {
      params,
    },
  );

  return response.data;
}
