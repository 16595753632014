import { useState } from 'react';

export function useAutoRefreshToggle() {
  const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(true);

  return {
    autoRefreshEnabled,
    enableAutoRefresh: () => setAutoRefreshEnabled(true),
    disableAutoRefresh: () => setAutoRefreshEnabled(false),
  };
}
